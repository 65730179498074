import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// 引入normalize.css
import "./assets/css/normalize.css";

import {
  Button,
  Carousel,
  CarouselItem,
  Link,
  Select,
  Option,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tabs,
  TabPane,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  Input,
  Table,
  TableColumn,
  MessageBox,
  Popover,
  Progress,
  Form,
  FormItem,
  Message,
  Autocomplete,
  Col,
  Empty,
  DatePicker,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Icon,
} from "element-ui";
Vue.use(Button)
  .use(Carousel)
  .use(CarouselItem)
  .use(Link)
  .use(Select)
  .use(Option)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Tabs)
  .use(TabPane)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Pagination)
  .use(Input)
  .use(Table)
  .use(TableColumn)
  .use(Popover)
  .use(Progress)
  .use(Form)
  .use(FormItem)
  .use(Autocomplete)
  .use(Col)
  .use(Empty)
  .use(DatePicker)
  .use(Menu)
  .use(Submenu)
  .use(MenuItem)
  .use(MenuItemGroup)
  .use(Icon);

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
Vue.prototype.axios = axios;
axios.defaults.baseURL = "/api";
// 登录拦截
axios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("eft")) {
      config.headers.token = localStorage.getItem("eft");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//
// axios.interceptors.response.use((response) => {
//   if (response.data.code == 22) {

//   }
//   return response;
// });

//路由拦截
router.beforeEach((to, from, next) => {
  if (to.meta.isLogin) {
    if (localStorage.getItem("eft")) {
      next();
    } else {
      // Message.success("请先登录!");
      store.state.loginMethods();
    }
  } else {
    next();
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
